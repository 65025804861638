export default {
	cinema: {
		address: 'ул.Советская, 11А',
		place: null,
		phoneNumbers: ['+7 (910) 508-20-44'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/kinokasimov',
		twitter: null,
		odnoklassniki: 'https://ok.ru/kinokasimov',
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CCfYIXks',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
